import { TransitionService, UIRouterGlobals } from "@uirouter/angular";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, combineLatest, distinctUntilChanged, filter, fromEvent, map, merge, of, take, zip } from "rxjs";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { IReport } from "@gtmhub/reporting/models";
import { filterActiveSessions } from "@gtmhub/sessions/redux/session-selectors";
import { byDateField } from "@gtmhub/util/sorting-utils";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { fromTransitionHook } from "@webapp/core/routing/rxjs";
import { ModuleFlag } from "@webapp/feature-toggles/models/feature-module.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { HomeScreenView } from "@webapp/home/models/home.models";
import { InsightboardRepositoryService } from "@webapp/insightboards/services/insightboard/insightboard-repository.service";
import { NavigationEvents, NavigationLifeCycleEvents } from "@webapp/navigation/components/navigation/navigation.events";
import { hasAccessToSpecialReports } from "@webapp/navigation/components/navigation/sub-navigation/reports-sub-navigation/reports-sub-navigation.util";
import { INavItem } from "@webapp/navigation/models/nav-items-list.models";
import { IScreen, Screens } from "@webapp/navigation/models/shared.models";
import { IsNavigationExpandedCache } from "@webapp/navigation/services/is-navigation-expanded.cache";
import { NavigationReportsCacheService } from "@webapp/navigation/services/navigation-reports-cache.service";
import { SettingsSubNavStateService } from "@webapp/navigation/services/settings/settings-sub-nav-state.service";
import { SettingsVisibilityService } from "@webapp/navigation/services/settings/settings-visibility.service";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { getSessionAndOkrViewState, setNavWidth } from "@webapp/navigation/utils/navigation.util";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { Session } from "@webapp/sessions/models/sessions.model";
import { SessionsRepository } from "@webapp/sessions/services/sessions-repository.service";
import { ARROW_LEFT, ARROW_RIGHT } from "@webapp/shared/utils/keys";
import { CurrentUserRepository } from "@webapp/users";
import { WhiteboardPermissionsService } from "@webapp/whiteboards/services/whiteboard-permissions.service";
import { NAV_WIDTH } from "../config";

const COLLAPSED_NAV_STATES = [
  "gtmhub.userProfile",
  "gtmhub.userLanguage",
  "gtmhub.sendChangePasswordRequest",
  "gtmhub.userNotificationSettings",
  "gtmhub.redirectToPlatform",
];

@UntilDestroy()
@Component({
  selector: "navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @ViewChild("navigation")
  public navigationWrapperElement: ElementRef<HTMLElement>;

  @Input()
  @HostBinding("class.left-nav-hidden")
  public hidden = false;

  @HostBinding("class.left-nav-expanded")
  public isNavExpanded = true;

  private isFeedEnabled = true;
  private isOkrGridEnabled = false;

  public screens: IScreen[] = [
    {
      icon: "home",
      state: this.isFeedEnabled && this.currentUserRepository.getUserSetting("homeScreen") === HomeScreenView.feed ? "gtmhub.home.feed" : "gtmhub.home.dashboard",
      key: "home",
      ariaLabel: "home",
      showScreenCondition$: of(true),
    },
    {
      icon: "goal",
      state: "gtmhub.goals.all",
      key: "okrs",
      subStates: ["gtmhub.goals", "gtmhub.sessions", "gtmhub.okrViews", "gtmhub.okrView", "gtmhub.workflow", "gtmhub.allOkrs"],
      ariaLabel: "okr_aria_label",
      showScreenCondition$: combineLatest([this.permissionsFacade.hasPermission$("AccessGoals"), this.editionFeatureService.hasFeature$("okrs")]).pipe(
        map(([hasPermission, hasFeature]) => hasPermission && hasFeature)
      ),
    },
    {
      icon: "kpi",
      state: "gtmhub.kpis",
      key: "kpis",
      subStates: ["gtmhub.kpis"],
      ariaLabel: "kpi_aria_label",
      showScreenCondition$: this.featureModuleService.isKpiModuleEnabled$(),
    },
    {
      icon: "task",
      state: "gtmhub.tasks",
      key: "tasks",
      subStates: ["gtmhub.tasks"],
      ariaLabel: "tasks_capital",
      showScreenCondition$: this.featureModuleService.isTaskModuleEnabled$(),
    },
    {
      icon: "whiteboards",
      state: "gtmhub.whiteboards",
      key: "whiteboards",
      subStates: ["gtmhub.whiteboard"],
      ariaLabel: "whiteboards",
      showScreenCondition$: this.whiteboardPermissionsService.hasPermissionToView$(),
    },
    {
      icon: "pie",
      state: "gtmhub.lists",
      key: "reports",
      subStates: ["gtmhub.lists", "gtmhub.list", "gtmhub.insightboard"],
      ariaLabel: "reports",
      showScreenCondition$: of(true),
    },
    {
      icon: "insights",
      state: "gtmhub.insightboards",
      key: "insightboards",
      subStates: ["gtmhub.insightboard", "gtmhub.insightboards"],
      ariaLabel: "insightboards",
      showScreenCondition$: combineLatest([this.permissionsFacade.hasPermission$("AccessInsightboards"), this.editionFeatureService.hasFeature$("insightboards")]).pipe(
        map(([hasPermission, hasFeature]) => hasPermission && hasFeature)
      ),
    },
    {
      icon: "users-group",
      state: "gtmhub.employees",
      key: "people",
      subStates: ["gtmhub.teams", "gtmhub.employees", "gtmhub.organization", "gtmhub.organization_chart"],
      ariaLabel: "employees",
      showScreenCondition$: this.permissionsFacade.hasPermission$("AccessPeople"),
    },
  ];

  /**
   * Listed as a separate entity from the rest of the screens, as the other list
   * is provided to the top-section-nav where all items are rendered.
   * The Settings link is rendered in the bottom-section-nav component.
   */
  public settingsScreen: IScreen = {
    icon: "manage",
    state: "gtmhub.configuration",
    key: "settings",
    subStates: [
      "gtmhub.configuration",
      "gtmhub.automation",
      "gtmhub.automationList",
      "gtmhub.usersList",
      "gtmhub.badges",
      "gtmhub.approveBadges",
      "gtmhub.roles",
      "gtmhub.dataflow",
    ],
    showScreenCondition$: this.settingsVisibility.showSettings$,
  };

  /**
   * Controls the styles applied to the icon of the currently active screen.
   */
  public activeScreen: Screens | null;
  private specialReportIds: string[] = [];

  /**
   * Keeps the info for when the initial OKRs and Reports screen links are ready.
   * It's used to notify when the navigation skeleton is ready to be removed.
   */
  private screenLinksLoaded = {
    okrs$: new Subject<void>(),
    reports$: new Subject<void>(),
  };

  constructor(
    private routerGlobals: UIRouterGlobals,
    private transitionService: TransitionService,
    private navigationReportsCache: NavigationReportsCacheService,
    private currentUserRepository: CurrentUserRepository,
    private insightboardRepositoryService: InsightboardRepositoryService,
    private featureModuleService: FeatureModuleService,
    private permissionsFacade: PermissionsFacade,
    private broadcastService: BroadcastService,
    private settingsVisibility: SettingsVisibilityService,
    private settingsSubNavStateService: SettingsSubNavStateService,
    private isNavigationExpandedCache: IsNavigationExpandedCache,
    private navigationItemsMediator: NavigationItemsMediator,
    private sessionsRepository: SessionsRepository,
    private editionFeatureService: EditionFeatureService,
    private cdr: ChangeDetectorRef,
    private featureToggleFacade: FeatureTogglesFacade,
    private whiteboardPermissionsService: WhiteboardPermissionsService,
    @Inject("$rootScope") private rootScope: IGtmhubRootScopeService,
    private zone: NgZone
  ) {}

  public ngOnInit(): void {
    combineLatest([this.featureToggleFacade.isFeatureAvailable$(ModuleFlag.Feed), this.featureToggleFacade.isFeatureAvailable$(FeatureFlag.OkrGrid)])
      .pipe(untilDestroyed(this))
      .subscribe(([isFeedEnabled, isOkrGridEnabled]) => {
        this.isFeedEnabled = isFeedEnabled;
        this.isOkrGridEnabled = isOkrGridEnabled;
        if (!isFeedEnabled) {
          this.currentUserRepository.setUserSetting({ homeScreen: HomeScreenView.dashboard });
        }
      });

    this.subscribeToStateTransitionChanges();
    this.subscribeToNavigationExpandChanges();
    this.subscribeToHomeTabChanges();

    setNavWidth({ expanded: NAV_WIDTH.expanded + "px", collapsed: NAV_WIDTH.collapsed + "px" });
    this.removeNavSkeletonOnDataLoad();
    this.setOkrsLinkStateParams();
    this.setReportsLinkStateParams();
    this.setSettingsLinkState();
  }

  public ngAfterViewInit(): void {
    this.bindKeydownListeners();
  }

  /**
   * Bind listeners for ARROW_RIGHT and ARROW_LEFT keydown -
   * switches the focus between the main left navigation and the currently open sub-navigation.
   */
  private bindKeydownListeners(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(this.navigationWrapperElement.nativeElement, "keydown")
        .pipe(
          filter(({ key }) => key === ARROW_RIGHT || key === ARROW_LEFT),
          untilDestroyed(this)
        )
        .subscribe((event) => {
          event.preventDefault();

          const toggleNavButtonSelector = event.key === ARROW_RIGHT ? "#sub-navigation toggle-nav button" : "#main-navigation a";
          const toggleNavButton = document.querySelector<HTMLElement>(toggleNavButtonSelector);

          if (toggleNavButton) {
            setTimeout(() => {
              toggleNavButton.focus();
            }, 100);
          }
        });
    });
  }

  /**
   * Sets the target session or OKR view when the user hits the OKRs link.
   *
   * If the user has visited the app before, take him to his last session.
   * If not, take him either to the only active session or to the one that he can create OKRs in and ends sooner than the others.
   */
  private setOkrsLinkStateParams(): void {
    this.navigationItemsMediator.loadRecents("sessions");

    this.navigationItemsMediator
      .getRecentItems$("sessions")
      .pipe(
        filter((recentSessions) => Boolean(recentSessions?.items)),
        map((recentSessions) => recentSessions.items[0]),
        distinctUntilChanged((previous, current) => previous?.id === current?.id),
        untilDestroyed(this)
      )
      .subscribe((lastVisitedSessionOrView: INavItem) => {
        // the user has visited at least one session or OKR view
        const states = getSessionAndOkrViewState(this.isOkrGridEnabled);

        if (lastVisitedSessionOrView) {
          const state = lastVisitedSessionOrView.uxcType === "session" ? states.session : states.okrView;
          const paramKey = lastVisitedSessionOrView.uxcType === "session" ? "planningSessionId" : "okrViewId";
          const params = { [paramKey]: lastVisitedSessionOrView.id };

          this.updateScreen("okrs", { state, params });
          this.screenLinksLoaded.okrs$.next();

          this.cdr.markForCheck();
          return;
        }

        // the user has not visited any session or view, supposedly there might be no sessions at all in the account
        this.sessionsRepository
          .getMap$()
          .pipe(take(1), untilDestroyed(this))
          .subscribe((map) => {
            const sessionId = this.getDefaultTargetSessionId(Array.from(map.values()));

            this.updateScreen("okrs", {
              state: sessionId ? states.session : "gtmhub.sessions",
              params: sessionId ? { planningSessionId: sessionId } : null,
            });
            this.screenLinksLoaded.okrs$.next();
            this.cdr.markForCheck();
          });
      });
  }

  /**
   * Redirect to the performance report page, when hitting the Reports link, if the user has access to it.
   */
  private setReportsLinkStateParams(): void {
    zip(
      this.permissionsFacade.hasPermission$("ManageData"),
      this.permissionsFacade.hasPermission$("AccessReportsAndDataSourceFilters"),
      this.permissionsFacade.hasPermission$("AccessInsightboards"),
      this.editionFeatureService.hasFeature$("setup.datasources")
    )
      .pipe(
        take(1),
        map(([canManageData, canAccessReportsAndDataSourceFilters, canAccessInsightboards, dataSourcesAvailable]) =>
          hasAccessToSpecialReports({ canManageData, canAccessReportsAndDataSourceFilters, canAccessInsightboards, dataSourcesAvailable })
        )
      )
      .subscribe((hasAccessToSpecialReports) => {
        if (hasAccessToSpecialReports) {
          this.insightboardRepositoryService.getReports$().subscribe((reports: IReport[]) => {
            this.navigationReportsCache.set(reports);

            const performanceReport = reports.find((report) => report.name.toLowerCase() === "performance report");
            this.updateScreen("reports", { state: "gtmhub.insightboard", params: { dashboardId: performanceReport.id } });

            this.specialReportIds = reports.map((report) => report.id);
            this.screenLinksLoaded.reports$.next();
          });
        } else {
          this.screenLinksLoaded.reports$.next();
        }
      });
  }

  /**
   * Set as Settings screen link state the first sub-page, which is actually accessible to the current user.
   */
  private setSettingsLinkState(): void {
    this.settingsSubNavStateService
      .firstVisibleState$()
      .pipe(take(1))
      .subscribe((state) => (this.settingsScreen.state = state));
  }

  /**
   * Adds 'active' styles to the icon in the navigation of the screen we're currently on.
   */
  private setActiveScreen(): void {
    const currentState = this.routerGlobals.current.name;

    // an exception for the Performance and Process reports - they are displayed under Reports but are part of the insightboards module
    if (currentState === "gtmhub.insightboard" || currentState.startsWith("gtmhub.insightboard.")) {
      const idParam = this.routerGlobals.params["dashboardId"];
      this.activeScreen = this.specialReportIds.includes(idParam) ? "reports" : "insightboards";
    } else {
      const allScreens = [...this.screens, this.settingsScreen];
      const currentScreen = allScreens.find(
        (screen) => currentState === screen.state || screen.subStates?.some((subState) => currentState === subState || currentState.startsWith(`${subState}.`))
      );
      this.activeScreen = currentScreen?.key || "home";
    }

    this.cdr.markForCheck();
  }

  /**
   * Several pages should not display a subnavigation when visited -
   * collapse or expand the sub-nav when entering or exiting such a page.
   */
  private setSubNavigationState(): void {
    if (COLLAPSED_NAV_STATES.includes(this.routerGlobals.current.name)) {
      this.isNavigationExpandedCache.set({ value: false, persistValue: false });
    } else {
      this.isNavigationExpandedCache.resetTempValue();
    }

    this.cdr.markForCheck();
  }

  /**
   * When there's no recently visited session or OKR view (new user),
   * pick a default session or the sessions list page to display to him.
   */
  private getDefaultTargetSessionId(sessions: Session[]): string {
    const activeSessions = filterActiveSessions(sessions);

    if (activeSessions.length === 0) {
      return null;
    }

    const chronoSortedSessions = activeSessions.sort(byDateField("end"));
    const sessionsUserCanCreateIn = chronoSortedSessions.filter((session) => this.currentUserRepository.allowedActionsSync(session.access).includes("create"));

    return sessionsUserCanCreateIn.length > 0 ? sessionsUserCanCreateIn[0].id : chronoSortedSessions[0].id;
  }

  /**
   * Assign the corresponding expanded/collapsed values to both this component props and the $rootScope (read elsewhere).
   */
  private subscribeToNavigationExpandChanges(): void {
    this.isNavigationExpandedCache
      .get$()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.isNavExpanded = value;
        this.rootScope.hideMenu = !value;
        this.rootScope.$evalAsync();
        this.cdr.markForCheck();
      });
  }

  /**
   * When the user navigates to a different tab of the Home page,
   * update the state, where the Home nav link leads to.
   */
  private subscribeToHomeTabChanges(): void {
    merge(
      this.broadcastService.on(NavigationEvents.SUB_NAV_SWITCH_TO_DASHBOARD).pipe(map(() => HomeScreenView.dashboard)),
      this.broadcastService.on(NavigationEvents.SUB_NAV_SWITCH_TO_FEED).pipe(map(() => HomeScreenView.feed))
    )
      .pipe(untilDestroyed(this))
      .subscribe((screen) => {
        const state = screen === HomeScreenView.feed ? "gtmhub.home.feed" : "gtmhub.home.dashboard";
        this.updateScreen("home", { state });
      });
  }

  /**
   * Update the active screen icom styles and navigation expanded/collapse state on transition completed.
   * When changing routes, the active styles on the corresponding icon link on the left nav denote which route is active.
   * There are also several states that temporarily collapse the sub-nav (e.g. Edit Profile) - this is also handled on transition success.
   */
  private subscribeToStateTransitionChanges(): void {
    fromTransitionHook(this.transitionService, "onSuccess", {})
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setActiveScreen();
        this.setSubNavigationState();
      });
  }

  /**
   * Updates the targeted screen config state and/or state params.
   */
  private updateScreen(screenKey: Screens, args: { state?: string; params?: Record<string, string> }): void {
    const screen = this.screens.find((screen) => screen.key === screenKey);

    screen.state = args.state ?? screen.state;
    screen.stateParams = args.params !== undefined ? args.params : screen.stateParams;
  }

  /**
   * Triggers the removal of the left nav skeleton.
   * It should be removed only when all initially unknown links are correctly assigned.
   */
  private removeNavSkeletonOnDataLoad(): void {
    zip([this.screenLinksLoaded.okrs$, this.screenLinksLoaded.reports$])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.setActiveScreen();
        this.setSubNavigationState();
        this.broadcastService.emit(NavigationLifeCycleEvents.NAV_LOADED);
        this.cdr.detectChanges();
      });
  }
}
