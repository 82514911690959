<ng-container *ngIf="!indicators.loading; else loading">
  <form-group (valueChange)="handleFormValueChange($event)">
    <text-form-item
      [value]="task.name"
      [labelText]="'title' | localize"
      [placeholder]="'enter_task_name' | localize"
      [required]="true"
      e2eTestId="task-modal-task-title"
      controlName="name"></text-form-item>

    <assignee-form-item
      [labelText]="'owner_cap' | localize"
      [placeholder]="'search_for_owner' | localize"
      [required]="true"
      [request]="ownerRequest"
      [atLeastOneValue]="true"
      [value]="task.ownerId ? [task.ownerId] : []"
      mode="default"
      e2eTestId="task-view-owner-selector"
      controlName="ownerId"></assignee-form-item>

    <task-status-form-item [value]="task.status" [required]="true" [labelText]="'status' | localize" controlName="status"></task-status-form-item>

    <date-picker-form-item [value]="task.dueDate" [labelText]="'due_date_cap' | localize" [allowClear]="true" [open]="false" [backdrop]="true" controlName="dueDate"></date-picker-form-item>

    <multi-selector-form-item
      [value]="selectedParent"
      [nodes]="nodes"
      [nodesLoading]="nodesLoading"
      [labelText]="'linked_to_cap' | localize"
      [sessionIds]="allOpenSessionsIds"
      [searchFn]="searchingFunc"
      (openChange)="onOpenChange($event)"
      controlName="linkedTo"></multi-selector-form-item>

    <rich-text-editor-with-mentions-form-item
      [value]="{ note: task.description }"
      [useGifs]="false"
      [useMentions]="false"
      [labelText]="'description_cap' | localize"
      [placeholder]="'add_description' | localize"
      (editorBlur)="onDescriptionUpdate()"
      controlName="description"></rich-text-editor-with-mentions-form-item>

    <custom-fields-form-item target="task" controlName="customFields" formName="create-task"></custom-fields-form-item>
  </form-group>
</ng-container>

<ng-template #loading>
  <ui-loading-indicator [uiTip]="'loading' | localize" uiSize="large"></ui-loading-indicator>
</ng-template>
